import { SocialLoginComponent } from './../social.login.component';
import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService } from 'src/app/common/services/config/config.service';

@Component({
  selector: 'ig-facebook-login',
  templateUrl: './facebook.login.component.html',
  styleUrls: ['./facebook.login.component.scss'],
})
export class FacebookLoginComponent {
  public static isUserLoggedIn: boolean;
  public static authResponse: any;
  public static userAccessToken: string;
  public static profilePicture: string;
  public static userFacebookId: string;

  @Output() public onLoginStatusChanged = new EventEmitter<boolean>();

  constructor() {
    this.initFB();
    this.syncFacebookStatus();
  }

  login() {
    window['FB'].login(
      (response: any) => {
        console.log('FB login response', response);

        if (!response || !response['authResponse']) {
          return;
        }

        this.syncFacebookStatus();
      },
      {scope: ConfigService.config.facebook.graphApi.scope}
    );
  }

  public get isUserLoggedWithFB() {
    return FacebookLoginComponent.isUserLoggedIn;
  }

  public get profilePicture() {
    return FacebookLoginComponent.profilePicture;
  }

  public logout() {
    window['FB'].logout(() => {
      this.syncFacebookStatus();
    });
  }

  private syncFacebookStatus() {
    try {
      window['FB'].getLoginStatus((response) => {
        // console.log('getLoginStatus response', response);

        if (response.status === 'connected') {
          FacebookLoginComponent.isUserLoggedIn = true;
          FacebookLoginComponent.authResponse = response;
          FacebookLoginComponent.userFacebookId = response.authResponse.userID;
          FacebookLoginComponent.userAccessToken =
            response.authResponse.accessToken;
          FacebookLoginComponent.profilePicture =
            ConfigService.config.facebook.graphApi.profilePicture.replace(
              '${userId}',
              response.authResponse.userID
            );

          // Same for Google login.
          // TravelBuddy uses this to load chat history
          localStorage.setItem(
            'social.login.facebookUser',
            JSON.stringify({
              userId: response.authResponse.userID,
              profilePicture: FacebookLoginComponent.profilePicture,
            })
          );

          SocialLoginComponent.loginStatus$.next(true);
        } else {
          FacebookLoginComponent.isUserLoggedIn = false;
          FacebookLoginComponent.authResponse = null;
          FacebookLoginComponent.userAccessToken = null;
          FacebookLoginComponent.profilePicture = null;
          FacebookLoginComponent.userFacebookId = null;
          localStorage.removeItem('social.login.facebookUser');
          SocialLoginComponent.loginStatus$.next(false);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private initFB() {
    window['FB'].init(ConfigService.config.facebook.sdkConfig);
  }
}
