<mat-form-field #field [appearance]="outline" color="primary" class="ig-field" [class]="cls">
  <mat-label>{{ label }}</mat-label>
  <input #input *ngIf="mode==='input'" matInput [formControl]="control" (focus)="onFocus($event)"
         [readonly]="isReadOnly" [maxlength]="maxlength" (click)="onClick($event)" (input)="onChange($event)">
  <textarea *ngIf="mode==='textarea'" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            [maxlength]="maxlength"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="45" [formControl]="control" (input)="onChange($event)"
            (focus)="onFocus($event)"></textarea>
  <mat-icon *ngIf="icon" matSuffix (click)="iconClick()">{{ icon }}</mat-icon>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
