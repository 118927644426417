import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { VisitorPlace } from './visitor.place';

export class TripAttraction {
  feedType: string;
  feedId: string;
  order: number;
  status?: string;
  item?: VisitorEvent | VisitorPlace; // need VisitorPlace later
  searchedItem?: any;
  prePlans?: {
    type: string,
    time: string,
    title: string,
    content: string
  }[];
  postPlans?: {
    type: string,
    time: string,
    title: string,
    content: string
  }[];
}

export class UserTrip {
  public id?: string;
  public description?: string;
  public userFacebookId: string;
  public timestamp?: Date;
  public hubName: string;
  public img?: string;
  public title: string;
  public attractions?: TripAttraction[];

  // for v3 multi-days trips
  public days: {
    date: Date;
    dateText: string;
    note: string;
    attractions: TripAttraction[];
  }[];
}

export class TripStorageCommand {
  public id: string;
  public _id?: string;  // v3 uses _id
  public userFacebookId: string;
  public userSocialId: string;
  public timestamp: Date;
  public trips: UserTripModel[];

  constructor() {
    this.trips = [];
  }
}

export class UserTripModel extends UserTrip {
  public isShowingDetails: boolean = false;
  public isSuccess: boolean = false;
}

export class CreateTripCommand extends TripStorageCommand {
  public newTrip: UserTrip;
}

export class TripPlan {
  type: string;
  time: string;
  title: string;
  content: string;
}
