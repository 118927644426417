<mat-form-field *ngIf="outline!=='plain'" appearance="fill" color="primary" (click)="$event.stopPropagation();">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="formControl">
  <mat-icon matSuffix *ngIf="!formControl.value">search</mat-icon>
  <button *ngIf="formControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<div *ngIf="outline==='plain'" class="relative">
  <input type="text" [formControl]="formControl" [placeholder]="label"
         class="bg-white w-full rounded-md px-3 py-2 h-9 text-gray-500 border border-gray-300 text-sm outline-none pr-12">
  <div class="absolute right-[13px] top-2 text-gray-400">
    <mat-icon matSuffix *ngIf="!formControl.value" class="text-[18px]">search</mat-icon>
    <mat-icon *ngIf="formControl.value" (click)="clear()" class="text-[18px] cursor-pointer">close</mat-icon>
  </div>
</div>
